.App {
  /* position: relative; */
  text-align: center;
  width:100vw;
  height:100vh;
  overflow: hidden;
  background-color: #282c34;
  display: flex;
  justify-content: center;
  align-items: center;
}

.webCam {
  position: absolute;
  text-align: center;
  z-index: 0;
  min-width: 100%;
  min-height: 100%;
  border-radius: "5px";
  filter: drop-shadow(6px 6px 6px #BD5109);
}
video {
    /* position: fixed; 
    right: 0; 
    bottom: 0; */
    min-width: 100%;
    min-height: 100%;
    width: auto; 
    height: auto; 
    /* background-size: cover; */
}
.container{
  position:absolute;
  width: 100%;
  height: 100%;
}
.logo-container {
   /* display: flex;
  justify-content: center;
  align-items: center; */
  /* position: absolute; */
  visibility: hidden;
  text-align: center;
  width: 60px;
  height: 60px;
  z-index: 1;
  transition: visibility 0.5s ease;
}

.logo-snipes {
  border-radius: 50%;
  width: 60px;
  height: 60px;
}

.canvas {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 9;
  width: 100%;
  height: 100%;
}




